import React from "react"
import { ConsentManager } from "@segment/consent-manager/"

const bannerContent = (
  <span className="text-xs lg:text-sm leading-tight">
    Wir verwenden Cookies auf unserer Webseite. Einige von ihnen sind
    not&shy;wendig, während andere uns helfen, die Webseite zu ver&shy;bessern.
    Du kannst die Ver&shy;wendung von nicht not&shy;wendigen Cookies ablehnen,
    indem Du auf die "Ablehnen" klickst. Wenn Du der Nutzung nicht
    not&shy;wendiger Cookies zu&shy;stimmst, klicke auf "Alle akz&shy;eptieren".
    Über "Privats&shy;phäre Ein&shy;stellungen" kannst Du selber
    ent&shy;scheiden, welcher Nutzung Du zustimmst. Weitere
    In&shy;for&shy;mationen, wie wir Deine Daten ver&shy;arbeiten, findest du in
    unserer{" "}
    <a href="/datenschutz" target="_blank" rel="noopener noreferrer">
      Datenschutzerklärung
    </a>
    .
  </span>
)
const bannerSubContent = (
  <div className="text-base">Privatsphäre Einstellungen</div>
)
const bannerActionsBlock = ({ acceptAll, denyAll }) => (
  <span className="mx-auto">
    <button
      className="w-11/12 inline-block p-4 font-bold mb-2 text-sm border border-custom-600 leading-none bg-custom-600 rounded transition duration-500 ease-in-out"
      onClick={acceptAll}
    >
      Alle akzeptieren
    </button>
    <button
      className="w-11/12 inline-block py-2 mb-0 px-4 m-2 font-normal text-sm leading-none border border-white hover:bg-red-500 rounded transition duration-500 ease-in-out"
      onClick={denyAll}
    >
      Ablehnen
    </button>
  </span>
)
const preferencesDialogTitle = "Privatsphäre Einstellungen"
const preferencesDialogContent = (
  <>
    <p>
      Segment verwendet die von Cookies und JavaScript-Bibliotheken gesammelten
      Daten zur Verbesserung zu verbessern, den Website-Verkehr zu analysieren,
      personalisierte Werbung zu schalten und die Gesamtleistung unserer Website
      zu steigern.
    </p>
    <p>
      Durch die Nutzung unserer Website erklärst Du dich mit unserer{" "}
      <a href="/datenschutz" target="_blank" rel="noopener noreferrer">
        Datenschutz&shy;erklärung
      </a>{" "}
      einverstanden .
    </p>
    <p>
      Die Tabelle zeigt, wie wir diese Daten nach Kategorien verwenden. Um eine
      Datenerhebung in einer Kategorie der Datenerfassung abzulehnen, wähle
      "Nein" und speichere Deine Einstellungen.
    </p>
  </>
)
const cancelDialogTitle = "Bist du sicher, dass du abbrechen möchest?"
const cancelDialogContent = (
  <>
    Deine Einstellungen wurden nicht gespeichert. Wenn Du unsere Website weiter
    nutzt, erklärst Du dich mit unserer{" "}
    <a href="/datenschutz" target="_blank" rel="noopener noreferrer">
      Datenschutzerklärung
    </a>{" "}
    einverstanden.
  </>
)

const ConsentManagerSettings = props => {
  return (
    <div className="fixed bottom-0 inset-x-0 segment-banner">
      <ConsentManager
        writeKey="y3bcVF8s0XXDkVrn4JMLG9IGsRyADBlD"
        otherWriteKeys={["y3bcVF8s0XXDkVrn4JMLG9IGsRyADBlD"]}
        bannerContent={bannerContent}
        bannerSubContent={bannerSubContent}
        bannerActionsBlock={props.bannerActionsBlock || bannerActionsBlock}
        bannerHideCloseButton={true}
        preferencesDialogTitle={preferencesDialogTitle}
        preferencesDialogContent={preferencesDialogContent}
        cancelDialogTitle={cancelDialogTitle}
        cancelDialogContent={cancelDialogContent}
        bannerBackgroundColor="#1f2937"
        bannerAsModal={true}
        preferencesDialogTemplate={{
          headings: {
            allowValue: "Erlauben",
            categoryValue: "Kategorie",
            purposeValue: "Zweck",
            toolsValue: "Dienste",
          },
          checkboxes: {
            noValue: "Nein",
            yesValue: "Ja",
          },
          actionButtons: {
            cancelValue: "Abbrechen",
            saveValue: "Speichern",
          },
          cancelDialogButtons: {
            cancelValue: "Abbrechen",
            backValue: "Zurück",
          },
          categories: [
            {
              key: "functional",
              name: "Funktional",
              description:
                "Zur Überwachung der Leistung unserer Website und zur Verbesserung Deines Surferlebnisses.",
              example: "Momentan nutzen wir keine dieser Cookies.",
            },
            {
              key: "marketing",
              name: "Marketing und Analyse",
              description:
                "Um das Nutzerverhalten zu verstehen, um Ihnen ein relevanteres Browsing-Erlebnis zu bieten oder den Inhalt unserer Website zu personalisieren.",
              example:
                "Wir sammeln zum Beispiel Informationen darüber, welche Seiten Sie besuchen, damit wir Ihnen relevantere Informationen präsentieren können.",
            },
            {
              key: "advertising",
              name: "Werbung",
              description:
                "Zur Personalisierung und Messung der Wirksamkeit unserer Werbung.",
              example:
                "Dadurch können wir gezielt unsere Werbung Personen zeigen, die sich dafür interessieren könnten.",
            },
            {
              key: "essential",
              name: "Notwendig",
              description:
                "Wir verwenden Browser-Cookies, die notwendig sind, damit die Website wie vorgesehen funktioniert.",
              example:
                "So speichern wir zum Beispiel Ihre Präferenzen bei der Datenerfassung auf der Website, damit wir sie berücksichtigen können, wenn Sie unsere Website erneut besuchen. Sie können diese Cookies in den Einstellungen Ihres Browsers deaktivieren, aber wenn Sie dies tun, funktioniert die Website möglicherweise nicht wie vorgesehen.",
            },
          ],
        }}
      />
    </div>
  )
}
export default ConsentManagerSettings
